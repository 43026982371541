@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:ital@0;1&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*
{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body 
{
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  color: #E8D9DC;
  background: url(/static/media/bg2.6d8535e8.png) no-repeat;;
  background-color: black;
  max-width: 100%;
  min-height: 100vh;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  color: #E8D9DC;
}

nav {
  text-align: left;
  width: 100%;
  position: relative;
  padding: 20px;
}

nav ul li {
  padding: 10px;
  display: inline-block;
}

nav ul li:hover {
  color: #c299a1;
  transform: scale(1.15);
  transition: .25s;
}

.front-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.front-page h1 {
  font-size: 46px;
}

.line1 {
  width: 0%;
	height: 120px;
	border-style:  solid;
	border-color: #E8D9DC;
  max-width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
}

.horizontal1 {
  width: 100%;
	height: 0px;
	border-style:  solid;
	border-color: #E8D9DC;
  max-width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
}

.horizontal2 {
  width: 100%;
	height: 0px;
	border-style:  solid;
	border-color: #E8D9DC;
  max-width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  align-self: left;
}

.about-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  padding: 10px;
  margin-top: 100px;
  margin-bottom: 100px;
}

.about-content .about-me {
  width: 50%;
  text-align: right;
}

.about-content .about-me h1 {
  font-size: 32px;
  padding: 10px;
}

.about-content .about-me p {
  padding: 10px;
}

.about-content .about-me a {
  color: #E8D9DC;
  text-decoration: none;
  padding: 5px;
}

.about-content .about-me a:hover {
  color: #c299a1;
  text-decoration: underline;
}

.skills-content {
  width: 100%;
}

.swimlane {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.swimlane:hover {
  transform: scale(1.15);
  transition: .5s;
}

.swimlane .icon {
  padding: 10px;
}

.flexyboy {
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-content {
  position: relative;
  width: 1100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 50px;
  margin-top: 50px;
}

.project-content .box {
  position: relative;
  width: 275px;
  height: 275px;
  overflow: hidden;
  transition: 0.5s;
}

.project-content .box:hover {
  z-index: 1;
  transform: scale(1.25);
  box-shadow: 0 25px 40px rgba(f, f, f, f);
}

.project-content .box .img-Box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.project-content .box .img-Box:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(0deg, #c299a1, #e8d9dc);
  mix-blend-mode: multiply;
  opacity: 0;
  transition: .5s;
}

.project-content .box:hover .img-Box:before {
  opacity: 1;
}

.project-content .box .imgBox img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-content .box .card-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  padding: 20px;
  align-items: flex-end;
}

.project-content .box .card-content h1 {
  transform: translateY(150px);
  transition: .5s;
  text-align: left;
  padding: 2px;
}

.project-content .box:hover .card-content h1
{
  transform: translateY(0);
}

.project-content .box .card-content h5 {
  transform: translateY(150px);
  transition: .5s;
  text-align: left;
  padding: 2px;
}

.project-content .box:hover .card-content h5
{
  transform: translateY(0);
}

.project-content .box .card-content p {
  transform: translateY(200px);
  transition: .5s;
  text-align: left;
  padding: 2px;
}

.project-content .box:hover .card-content p {
  transform: translateY(0);
  transition-delay: .2s;
}

.project-content .box .card-content .links {
  transform: translateY(200px);
  transition: .5s;
  text-align: left;
  padding: 2px;
}

.project-content .box:hover .card-content .links {
  transform: translateY(0);
  transition-delay: .4s;
}

.project-content .box:hover .card-content a {
  color: white;
  text-decoration: none;
}

.project-content .box:hover .card-content a:hover {
  text-decoration: underline;
}

.footer {
  font-size: 10px;
  text-align: right;
  padding: 4px;
}

@media screen and (max-width: 1000px) {
  .about-content {
    flex-direction: column;
  }

  .about-content .about-me {
    width: 100%;
  }

  .swimlane {
    flex-direction: column;
  }

  .about-content .skills-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding: 10px;
  }

  .about-content .skills-content .horizontal2 {
    display: none;
  }
}

@media screen and (max-width: 600px) {

  .about-content {
    flex-direction: column;
  }

  nav {
    text-align: center;
  }

  .about-content .about-me {
    width: 100%;
    text-align: center;
  }

  .swimlane {
    flex-direction: column;
  }

  .about-content .skills-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding: 10px;
  }

  .about-content .skills-content .horizontal2 {
    display: none;
  }
}

